import React, { useState, Component } from "react";
import { NavLink, withRouter } from 'react-router-dom'
import { MDBFooter } from "mdbreact";
import MyMap from '../Map'
import './style.css'


class FooterPage extends Component {

  render() {

    return (
      <MDBFooter className="footer p-2">
        <div className="row" style={{ fontSize: 15 }}>
          <div className='col-3'>
            <MyMap height={150}/>
          </div>
          <div className='col'>
            <div className='row text-center text-md-left' style={{ paddingRight: '4rem', justifyContent: 'space-between' }}>
              {/* <div className="d-block m-3 justify-content-center" > */}
              <div className="d-block m-3 justify-content-center" >
                <div>Calle Zaragoza, 27</div>
                <div>50690 Pedrola</div>
                <div>Zaragoza (España)</div>
              </div>
              <div style={{ display: 'grid' }}>
                <a href="tel:+34690051174" style={{ alignSelf: 'center' }}>
                  <span className="tlf" style={{ alignSelf: 'end', color: '#a9acb9', fontSize: 15 }} >690 05 11 74</span>
                </a>
              </div>
              <div style={{ display: 'grid' }}>
                <a href="mailto:info@fisioterapiapinseque.com" style={{ alignSelf: 'center' }}>
                  <span className="mail" style={{ alignSelf: 'end', color: '#a9acb9' }} >info@fisioterapiapinseque.com</span>
                </a>
              </div>
              <div style={{ display: 'grid' }}>
                <NavLink to='/policy' style={{ alignSelf: 'center' }}>
                  <span className="policy" style={{ alignSelf: 'center', color: '#a9acb9' }}>Política de privacidad</span>
                </NavLink>
              </div>
            </div>
            <div>
              {new Date().getFullYear()} Fisioterapia Pedrola
            </div>
          </div>
        </div>
      </MDBFooter>
    );
  }

}

export default FooterPage;