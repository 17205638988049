import React from 'react';
import { Carousel } from 'react-responsive-carousel'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import HouseIcon from '@material-ui/icons/House';


class Clinica extends React.Component {

    constructor() {
        super()
        this.state = {
            show: false,
        };
    }

    showDetail = (e) => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const data = [
            { image: 'clinica/entrada2.jpg' },
            { image: 'clinica/letrero.jpeg' },
            { image: 'clinica/camilla2.jpeg' },
            { image: 'clinica/puertabaño.jpeg' },
            { image: 'clinica/entrada1.jpg' },
            { image: 'clinica/mesa.jpeg' },
            { image: 'clinica/logosbaños.jpeg' },
            { image: 'clinica/camilla.jpeg' },
            { image: 'clinica/entrada.jpeg' },
            { image: 'clinica/material.jpeg' },

        ]
        return (
            <div className="card-container" >
                <Card
                    className="card"
                    onClick={this.state.show ? null : this.showDetail}
                    style={{ width: "100%" , background: 'whitesmoke' }}
                >
                    {this.state.show ?
                        <>
                            <div className="option-title clinica-title">
                                <ExpandLessIcon
                                    className="expand-up-icon"
                                    fontSize="large"
                                    style={{ color: "aqua" }}
                                    onClick={this.showDetail} />
                                <CardContent>
                                    <h2>Clínica</h2>
                                </CardContent>
                            </div>
                            <div className="text-center py-2 inicio-text">
                                <p>
                                    <span>Fisioterapia Pinseque</span> nace con la idea de poder ofrecer el mejor servicio de fisioterapia a sus pacientes. Para ello contamos con unas instalaciones
          que favorecen un entorno cálido, agradable y confortable,
            ideal para el buen desarrollo de nuestros servicios y actividades.
                                </p>
                            </div>
                            <div style={{ alignContent: 'content', border: 'solid 2px aqua' }}>
                                <Carousel
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval="4000"
                                    infiniteLoop
                                    autoPlay
                                >
                                    {data.map((item) => {
                                        return (
                                            <ul className="list-unstyled carousel">
                                                <li key={item.id}>
                                                    <img alt={item.id} src={require(`../../images/${item.image}`)} height='250px' />
                                                </li>
                                            </ul>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <ExpandLessIcon
                                fontSize="large"
                                style={{ color: "aqua" }}
                                onClick={this.showDetail} />
                        </>
                        :
                        <CardActionArea >

                            <div className="d-flex justify-content-center" >
                                <HouseIcon
                                    className="align-self-center mr-3 clinica-icon"
                                    fontSize="large"
                                />
                                <h4>
                                    Clínica
                                </h4>
                            </div>

                        </CardActionArea>
                    }
                </Card>
            </div>
        );
    }
}


export default Clinica