import React, { useState } from 'react';
import './style.css'
import CssBaseline from '@material-ui/core/CssBaseline';
import MyMap from '../Map'
import credentials from '../../data/credentials'
import iconmap from '../../images/icon/ubicacion.png'
import icontlf from '../../images/icon/telefono.png'
import iconmail from '../../images/icon/mail.png'
import FormContacto from '../form'
import { navigateFunction } from '../../functions';

function Page() {

    const stylesform = {
        zIndex: '2',
        padding: '1rem 2rem',
        borderRadius: "50px",
        float: "right",
        background: "#9FD6D6",
        width: '500',
        alignItems: 'center'
    }
    const [hovered, setHovered] = useState(null)

    return (

        <div className='contactos-page'>
            <CssBaseline />
            <div
                className="row d-flex justify-content-center"
                style={{ marginTop: '0px' }}
            >
                <div className="col justify-content-center text-center border-right">
                    <a onClick={() => navigateFunction(41.7363098, -1.1015243)} href='#'>
                        <div >
                            <img src={iconmap} width="60" height="60" alt="iconmap" />
                        </div>
                    </a>
                    <div className="d-block m-3 justify-content-center" >
                        <div>Calle Zaragoza, 27</div>
                        <div>50690 Pedrola</div>
                        <div>Zaragoza (España)</div>
                    </div>
                </div>

                <div className="col justify-content-center text-center border-right">
                    <a href="tel:+34690051174">
                        <img src={icontlf} width="60" height="60" alt="icontlf" />
                    </a>
                    <div className="d-block m-3 justify-content-center">
                        <span className="tlf">690 05 11 74</span>
                    </div>
                </div>

                <div className="col justify-content-center text-center">
                    <a href="mailto:info@fisioterapiapinseque.com">
                        <img src={iconmail} width="60" height="60" alt="iconmail" />
                    </a>
                    <div className="d-block m-3 justify-content-center">
                        <span className="mail">info@fisioterapiapinseque.com</span>
                    </div>
                </div>

            </div>

            <div className="row mt-5 mb-5"
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
            >

                <div className="col-6" style={{ zIndex: '1' }}>
                    <div style={{ height: '350px' }}>
                        <MyMap />
                    </div>
                    <div style={{ color: '#616363', fontSize: 35, fontFamily: 'moviebold', textTransform: 'uppercase' }}>
                        <span >
                            <span style={{ textDecoration: 'underline' }}>lunes a viernes</span>: 9:00-13:00 y 16:30-21:30
                        </span>
                    </div>
                </div>

                <div
                    style={stylesform}>
                    <h3 className="row mt-2" style={{ color: "white", display: 'block' }}>Déjanos un mensaje</h3>
                    <div className='row'><FormContacto /></div>
                </div>

            </div>
        </div>
    )
}

export default Page