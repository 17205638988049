import React from 'react';
import { Carousel } from 'react-responsive-carousel'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import logoblanco from '../../images/logoblanco.png'
import logo from '../../images/logonegro.png'

class Formacion extends React.Component {

    constructor() {
        super()
        this.state = {
            show: false,
        };
    }

    showDetail = (e) => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const data = [
            { image: 'formacion/formacion1.JPG' },
            { image: 'formacion/formacion2.JPG' },
            { image: 'formacion/formacion3.JPG' },
            { image: 'formacion/formacion4.JPG' },
            { image: 'formacion/formacion5.JPG' },
            { image: 'formacion/formacion6.jpeg' },
        ]

        return (
            <div className="card-container" >
                <Card
                    className="card"
                    onClick={this.state.show ? null : this.showDetail}
                    style={{
                        width: "100%",
                        background: 'whitesmoke',
                    }}
                >
                    {this.state.show ?
                        <>
                            <div className="option-title formacion-title">
                                <ExpandLessIcon
                                    className="expand-up-icon"
                                    fontSize="large"
                                    style={{ color: "rgb(0, 255, 179)" }}
                                    onClick={this.showDetail} />
                                <CardContent>
                                    <h2>Formación</h2>
                                </CardContent>
                            </div>

                            <div className="text-center py-2 inicio-text">
                                <p>
                                    En la práctica de cualquier rama en salud, y por supuesto en fisioterapia, consideramos
                                    fundamental que se base en la evidencia científica, para ello es necesario actualizarnos
                                    y formarnos constantemente como profesionales, con el fin de ofrecer el mejor tratamiento y
                                    abordaje a nuestros pacientes.
                                </p>
                            </div>
                            <div style={{ alignContent: 'content', border: 'solid 2px rgb(0, 255, 179)' }}>
                                <Carousel
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval="4000"
                                    infiniteLoop
                                    autoPlay
                                >
                                    {data.map((item) => {
                                        return (
                                            <ul className="list-unstyled carousel">
                                                <li key={item.id}>
                                                    <img alt={item.id} src={require(`../../images/${item.image}`)} height='250px' />
                                                </li>
                                            </ul>
                                        )
                                    })}
                                </Carousel>
                            </div>
                            <div className="text-center py-2 inicio-text">
                                <p>
                                    En 2016, de la mano de Javier Vidal, nació en Zaragoza
                                    <span style={{ color: '#2badad', fontWeight: 'bold' }}>
                                        &nbsp; Scienze Formación
                                    </span>,
                                    generando un espacio en el que aprender, desaprender y reaprender, donde los fisioterapeutas
                                    y otros compañeros que trabajan en Salud se convierten en mejores profesionales.
                                    Fisioterapia Pinseque se esfuerza día a día y mantiene sus conociminetos clínicos actualizados haciendo que
                                    vosotros recibáis tratamientos que realmente han sido contrastados con evidencias empíricas.
                                </p>
                                <p>
                                    Sitio web de
                                    <span style={{ color: '#2badad', fontWeight: 'bold' }}>
                                        &nbsp; Scienze Formación
                                    </span>
                                </p>
                                <a
                                    href='https://scienzeformacion.es'
                                    target="_blank"
                                    rel="noopener noreferrer">           
                                    <img src={logoblanco} width='100px' height='100px' style={{ borderRadius: '50%' }} />

                                </a>
                            </div>

                           
                            <ExpandLessIcon
                                fontSize="large"
                                style={{ color: "rgb(0, 255, 179)" }}
                                onClick={this.showDetail} />
                            {/* <div className='row' >
                <div className='col' style={{ paddingLeft: 50, paddingTop: 50, lineHeight: 3 , maxWidth: 950}}>
                    <p>
                        En 2016, de la mano de Javier Vidal, nació en Zaragoza 
                        <span style={{ color: '#2badad' , fontWeight: 'bold' }}>
                        &nbsp; Scienze Formación
                        </span>,
                         generando un espacio en el que aprender, desaprender y reaprender, donde los fisioterapeutas
                         y otros compañeros que trabajan en Salud se convierten en mejores profesionales.
                           Fisioterapia Pinseque se esfuerza día a día y mantiene sus conociminetos clínicos actualizados haciendo que 
                            vosotros recibáis tratamientos que realmente han sido contrastados con evidencias empíricas.
                    </p>
                </div>
                <div className='col-6' style={{ marginBottom: 60 }} >
                    <div className="frparallaxlarge frprx3" />
                    <div className="frparallaxlarge frprx2" />
                    <div className="frparallaxlarge frprx6" />
                </div>
            </div>

            <div className='row' >
                <div className='col-4' style={{ paddingTop: 90 }}>
                    <div className="frparallax frprx1" />
                    <div className="frparallax frprx4" />
                </div>
                <div className='col' style={{ paddingRight: 225 }}>
                    <p>
                        Sitio web de ScienzeFormación
                    </p>
                    <a
                        href='https://scienzeformacion.es'
                        target="_blank">
                        <img src={logo} width='100px' height='100px' style={{ borderRadius: '50%' }} />
                    </a>
                    <hr />
                    <ul className='list-unstyled nav-scienze' style={{ color: 'black' }}>
                        <li><a href='https://www.scienzeformacion.es/#cursos' target="_blank">Cursos</a></li>
                        <li><a href='https://www.scienzeformacion.es/videos' target="_blank">Videos</a></li>
                        <li><a href='https://www.scienzeformacion.es/#docentes' target="_blank">Docentes</a></li>
                        <li><a href='https://www.scienzeformacion.es/blog' target="_blank">Blog</a></li>
                        <li><a href='https://www.scienzeformacion.es/#contacto' target="_blank">Contacto</a></li>
                    </ul>
                </div> */}


                            {/* </div> */}
                        </>
                        :
                        <CardActionArea>
                            <div className="d-flex justify-content-center">
                                <LocalLibraryIcon
                                    className="align-self-center mr-3 formacionicon"
                                    fontSize="large"
                                />
                                <h4>
                                    Formación
                                </h4>
                            </div>
                        </CardActionArea>
                    }
                </Card>
            </div>
        );
    }
}


export default Formacion