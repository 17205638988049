import React, { Fragment, useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import './style.css';

function Page(props) {
    const {
        results,
        goTo,
    } = props;

    useEffect(
        () => {
            window.addEventListener('resize', handleResize)
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    )

    const firstrender = window.innerWidth > 1125 ? false : true

    const [tablet, setTablet] = useState(firstrender)

    const handleResize = () => {
        window.innerWidth > 1125 ?
            setTablet(false)
            :
            setTablet(true);

        console.log(tablet)
    }

    const style = { listStyle: 'none', columnCount: tablet ? 1 : 3 }

    return (
        <Fragment>
            <CssBaseline />

            <div className="results-page">
                <ul className="nonestyle-list" style={style}>
                    {results.map(item =>
                        <div
                            key={item.id}
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                            className="card-container"
                        >
                            <li>
                                <Card
                                    className="card"
                                    onClick={() => goTo(`/details/${item.id}`)}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            className="card-media"
                                            image={require(`../../images/${item.image}`)}
                                            title={item.title}
                                            style={{ borderRadius: "15px" }}
                                        />
                                        <CardContent
                                            style={{
                                                paddingBottom: '0px',
                                            }}>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                                style={{
                                                    textAlign: 'center',
                                                    fontFamily: 'moviebold',
                                                    color: '#2BADAD',
                                                    fontSize: '40px',
                                                    textTransform: 'uppercase'
                                                }}>
                                                {item.title}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </li>
                        </div>)
                    }
                </ul>
            </div>

        </Fragment>
    );
}

export default Page;
