// Dependencias
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Componentes
import FormContacto from './FormContacto';

// Acciones
import {
  addPhrase,
  deletePhrase,
  fetchPhrases,
} from '../../redux/actions/phrasesActions';

const mapStateToProps = ({ phrases }) => ({
  phrases: phrases.phrases
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    addPhrase,
    deletePhrase,
    fetchPhrases,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormContacto);
