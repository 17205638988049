import React from 'react'
import Page from './page'

class Contacto extends React.Component {
    render(){
        return (  
            <Page/>
              )
    }
 }
 export default Contacto