// Base de datos de Firebase
import database from '../../firebase/database';

// Tipos de acciones
import {
  FETCH_PHRASE_REQUEST,
  FETCH_PHRASE_SUCCESS,
  ADD_PHRASE_REQUEST,
  DELETE_PHRASE_REQUEST,
  DELETE_PHRASE_SUCCESS,
  // UPDATE_PHRASE_REQUEST,
  // UPDATE_PHRASE_SUCCESS,
  // UPDATE_PHRASE_ERROR
} from './actionTypes';

// Acciones de base
import { request, received } from '../baseActions';

export const fetchPhrases = () => dispatch => {
  // Enviamos la acción FETCH_PHRASE_REQUEST
  dispatch(request(FETCH_PHRASE_REQUEST));

  // Escuchamos las filas añadidas
  database.on('child_added', snapshot => {
    dispatch(received(FETCH_PHRASE_SUCCESS, { key: snapshot.key, ...snapshot.val() }));
  });

  // // Escuchamos las filas actualizadas
  // database.on('child_changed', snapshot => {
  //   dispatch(received(UPDATE_PHRASE_SUCCESS, { key: snapshot.key, ...snapshot.val() }));
  // });

  // Escuchamos las filas eliminadas
  database.on('child_removed', snapshot => {
    dispatch(received(DELETE_PHRASE_SUCCESS, { key: snapshot.key }));
  });
};

export const addPhrase = (name, email, msg, tel, date) => dispatch => {
  // Enviamos la acción ADD_PHRASE_REQUESTn
  dispatch(request(ADD_PHRASE_REQUEST));

  // Añadimos un nuevo elemento pulsando ref.
  // NOTA: Una vez que esto se ejecuta, el oyente estará en fetchPhrases (child_added)
  database.push({
    name,
    email,
    msg, 
    tel, 
    date
  });

}

export const deletePhrase = key => dispatch => {
  // Enviamos la acción DELETE_PHRASE_REQUEST
  dispatch(request(DELETE_PHRASE_REQUEST));

  // Eliminamos elementos mediante clave
  // NOTA: Una vez que esto se ejecuta, el oyente estará en fetchPhrases (child_removed)
  database.child(key).remove();
}

// export const updatePhrase = (key, phrase, author) => dispatch => {
//   // Enviamos la acción UPDATE_PHRASE_REQUEST 
//   dispatch(request(UPDATE_PHRASE_REQUEST));

//   // Recdpilamos los datos...
//   const data = {
//     phrase,
//     author
//   };

//   // Actualizamos un elemento mediante clave y datos
//   database
//     .child(key) // Primero seleccionamos un elemento mediante una clave
//     .update(data) // En este punto actualizamos los datos
//     .then(() => database.once('value')) // Devolvemos los datos actualizados
//     .then(snapshot => snapshot.val()) // Obtenemos los valores reales de la instantánea
//     .catch(error => {
//       // Si hay algún error, enviamos la acción error
//       dispatch(request(UPDATE_PHRASE_ERROR));

//       return {
//         errorCode: error.code,
//         errorMessage: error.message
//       };
//     });
// };
