import React  from 'react'
import img1 from '../../images/tarifas/fisio.jpeg'
import img2 from '../../images/tarifas/ejdir.jpeg'
import imgDownload from '../../images/tarifas/Tarifas2022FisioPinseque.pdf'
import './style.css'

const Tarifas = () => {
    const styleBtn = {
        background: '#9fd6d6',
        fontWeight: 'bold',
        fontStyle: 'caviar',
        fontSize: 20,
        position: 'fixed',
        top: 190,
        left: 50,
        padding: 7,
        borderRadius: 150
    }

    return (
        <>
            <div
                className='d-flex p-4 tarifas-page'
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className='row'>
                    <div className='col-lg-6 col-md-12 text-center'>
                        <img src={img1} alt='imgFisio' />
                    </div>
                    <div className='col-lg text-center'>
                        <img src={img2} alt='imgEjer' />
                    </div>
                </div>
                {/* 
            <div style={{ position: 'fixed', top: '100px', background: 'red' }}>
                <ReactModal
                    isOpen={showFisio}
                    onRequestClose={() => { setShowFisio(false) }}

                >
                    <img src={img1} alt='imgFisio' />
                </ReactModal>
            </div> */}
            </div >
            <div className='justify-content-center text-center' style={styleBtn} >
                <a href={imgDownload} style={{ color: 'white' }} download >
                    DESCARGAR
                </a>
            </div>
        </>
    )
}

export default Tarifas
