import React, { Component, Fragment } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import PhoneIcon from '@material-ui/icons/Phone';
import FormContacto from '../form';
import iconmap from '../../images/icon/ubicacion.png'
import icontlf from '../../images/icon/telefono.png'
import iconmail from '../../images/icon/mail.png'
import MyMap from '../Map'
import credentials from '../../data/credentials'
import {navigateFunction} from '../../functions'

class Contacto extends Component {

    constructor(props){
        super(props)
        this.state = {
            show : false, 
        };
    }

    showDetail = (e) =>{
        this.setState({
           show: !this.state.show
        })
    }

    render() {
        
        return (
            <Fragment>
            <CssBaseline />
                <div className="card-container" >
                        <Card
                            id = "contactocard"
                            className="card contactocard"
                            onClick={ this.state.show? null : this.showDetail }
                            style = {{width: "100%" , background: 'whitesmoke'}}
                            >
                            {this.state.show?
                                <>
                                <div className="option-title contacto-title">
                                    <ExpandLessIcon
                                    className = "expand-up-icon" 
                                    fontSize="large" 
                                    style={{ color: "khaki" }} 
                                    onClick={this.showDetail}/>
                                    <CardContent>
                                        <h2>Contacto</h2>
                                    </CardContent>
                                </div>
                                <div className = "container mt-5">
                                    <h3>Información de contacto</h3>
                                   <div className = "container mt-4" > 
                                       <a href="tel:+34690051174">
                                            <img src={icontlf} width="35" height="35" className="" alt=""/>
                                            <span className = "ml-2">690 05 11 74</span>
                                       </a>
                                    </div> 
                                   <div className = "container mt-4"> 
                                        <a href="mailto:info@fisioterapiapinseque.com">
                                            <img src={iconmail} width="35" height="35" className="" alt=""/>
                                            <span className = "ml-2">info@fisioterapiapinseque.com</span>
                                        </a>
                                    </div> 
                                   <div className = "container mt-4"> 
                                        <img src={iconmap} width="35" height="35" className="" alt=""/>
                                        <div>Calle San Pedro Mártir, 15</div>
                                        <div>50298 Pinseque</div>
                                        <div>Zaragoza (España)</div>    
                                        <button 
                                        className = "btn btn-primary mt-4 mb-4"
                                        onClick = {() => navigateFunction(41.7363098, -1.1015243)} 
                                        >
                                            Ver en mi dispositivo 
                                        </button>
                                    </div> 
                                    <div className= "d-flex">
                                        <MyMap/>
                                    </div>
                                    <div className = 'mt-5'>
                                        <h3>Formulario de contacto</h3>
                                        <FormContacto isMobile/>
                                    </div>
                                    
                                </div>
                                
                                <ExpandLessIcon
                                className = "expand-icon" 
                                fontSize="large" 
                                style={{ color: "khaki"}} 
                                onClick={this.showDetail}/>
                                </>
                                :
                                <CardActionArea>
                                    
                                        <div className = "d-flex justify-content-center">
                                            <PhoneIcon 
                                            className="align-self-center mr-3 contactoicon" 
                                            fontSize="large"
                                            />
                                            <h4>
                                                Contacto
                                            </h4>
                                        </div>
                                    
                                </CardActionArea>
                            }
                        </Card>
                    
                </div>
        </Fragment>

        );
    }
}

export default Contacto