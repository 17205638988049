import React, { useState } from 'react'
import './style.css'
import logoblanco from '../../images/logoblanco.png'
import logo from '../../images/logonegro.png'

const Formacion = () => {

    const [hover, setHover] = useState(true)

    return (
        <div
            className='formacion text-center'
            style={{
                fontFamily: 'arca',
                fontSize: 25,
                alignContent: 'center',
                padding: 15,
            }} >
            <div className='row' >
                <div className='col-4'>
                    <div className="frparallax frprxlogo" />
                    <div className="frparallax frprx5" />
                </div>
                <div className='col' style={{ paddingTop: 0, paddingRight: 225, lineHeight: 3, maxWidth: 1000 }}>
                    <p>
                        En la práctica de cualquier rama en salud, y por supuesto en fisioterapia, consideramos
                        fundamental que se base en la evidencia científica, para ello es necesario actualizarnos
                        y formarnos constantemente como profesionales con el fin de ofrecer el mejor tratamiento y
                        abordaje a nuestros pacientes.
                    </p>

                </div>
            </div>

            <div className='row' >
                <div className='col' style={{ paddingLeft: 50, paddingTop: 50, lineHeight: 3 , maxWidth: 950}}>
                    <p>
                        En 2016, de la mano de Javier Vidal, nació en Zaragoza 
                        <span style={{ color: '#2badad' , fontWeight: 'bold' }}>
                        &nbsp; Scienze Formación
                        </span>,
                         generando un nuevo espacio en el que aprender, desaprender y reaprender, donde los fisioterapeutas
                         y otros compañeros que trabajan en Salud se convierten en mejores profesionales.
                           Fisioterapia Pinseque se esfuerza día a día y mantiene sus conociminetos clínicos actualizados haciendo que 
                            vosotros recibáis tratamientos que realmente han sido contrastados con evidencias empíricas.
                    </p>
                </div>
                <div className='col-6' style={{ marginBottom: 60 }} >
                    <div className="frparallaxlarge frprx3" />
                    <div className="frparallaxlarge frprx2" />
                    <div className="frparallaxlarge frprx6" />
                </div>
            </div>

            <div className='row' >
                <div className='col-4' style={{ paddingTop: 90 }}>
                    <div className="frparallax frprx1" />
                    <div className="frparallax frprx4" />
                </div>
                <div className='col' style={{ paddingRight: 225 }}>
                    <p>
                        Sitio web de ScienzeFormación
                    </p>
                    <a
                        href='https://scienzeformacion.es'
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() => setHover(false)}
                        onMouseLeave={() => setHover(true)}>
                        {/* <div style = {{borderRadius: '50%'}}> */}
                        <img src={hover ? logoblanco : logo} width='100px' height='100px' style={{ borderRadius: '50%' }} />
                        {/* </div> */}
                    </a>
                    <hr />
                    <ul className='list-unstyled nav-scienze' style={{ color: 'black' }}>
                        <li><a href='https://www.scienzeformacion.es/#cursos' target="_blank" rel="noopener noreferrer">Cursos</a></li>
                        <li><a href='https://www.scienzeformacion.es/videos' target="_blank" rel="noopener noreferrer">Videos</a></li>
                        <li><a href='https://www.scienzeformacion.es/#docentes' target="_blank" rel="noopener noreferrer">Docentes</a></li>
                        <li><a href='https://www.scienzeformacion.es/blog' target="_blank" rel="noopener noreferrer">Blog</a></li>
                        <li><a href='https://www.scienzeformacion.es/#contacto' target="_blank" rel="noopener noreferrer">Contacto</a></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Formacion
