import React, { useState } from 'react'

import EuroIcon from '@material-ui/icons/Euro';
import FitnessCenterIcon from '../../images/manos_gris.png';
import HouseIcon from '@material-ui/icons/House';
import PeopleIcon from '@material-ui/icons/People';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PhoneIcon from '@material-ui/icons/Phone';

const ItemRender = (props) => {

    const { item: { name, icon, coloricon, textlink } } = props

    const icons = [
        {
            name: 'EuroIcon',
            object: EuroIcon
        },
        {
            name: 'HeadsetMicIcon',
            object: HeadsetMicIcon
        },
        {
            name: 'LocalLibraryIcon',
            object: LocalLibraryIcon
        },
        {
            name: 'HouseIcon',
            object: HouseIcon
        },
        {
            name: 'PeopleIcon',
            object: PeopleIcon
        },
        {
            name: 'FitnessCenterIcon',
            object: null
        },
        {
            name: 'PhoneIcon',
            object: PhoneIcon
        },
    ]

    const current = icons.find((e) => {
        return e.name === icon
    })

    const SvgIcon = current.object

    const styletitle = {
        color: coloricon,
        background: '#FFFFFF',
        height: '75px',
        width: '75px',
        transition: 'width .8s,height.8s',
        borderRadius: '25px',
    }

    const title = () => {
        return (
            <div
                className="card p-3 cardtitlehidden"
                style={styletitle}
                onMouseEnter={() => setContentcard(content)}>
                {current.name !== 'FitnessCenterIcon' ?
                    <SvgIcon fontSize='large' />
                    :
                    <img src={FitnessCenterIcon} fontSize='large' width= '35px' height='35' />
                }
            </div>
        )
    }

    const stylecontent = {
        color: coloricon,
        background: coloricon,
        height: "150px",
        width: "350px",
        transition: 'width 1s,height 1s',
        borderRadius: '25px',
    }

    const stylecontentheader = {
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        background: 'white',
        top: '10px',
        left: '9rem',
        width: '100%',
        height: '35px'
    }

    const styleheaderinfo = {
        display: 'flex',
        justifyContent: 'center',
        alingItems: 'center'
    }

    const content = () => {

        return (

            <div className="card p-0 cardtitle" style={stylecontent} onMouseLeave={() => setContentcard(title)}>

                <div style={stylecontentheader}>
                    <div style={styleheaderinfo}>
                        <div>
                            {current.name !== 'FitnessCenterIcon' ?
                                <SvgIcon className='d-inline' fontSize='large' />
                                :
                                <img src={FitnessCenterIcon} fontSize='large' width= '35px' height='35' />
                            }
                            {/* <h1 className='d-inline' style={{ marginLeft: '2rem' , fontSize: 21 }}>{name}</h1> */}
                        </div>
                    </div>
                </div>
                <div className='justify-content-center text-center' style={{ marginTop: 35 }}>
                    <span style={{ color: 'white', fontSize: 25 }}>{textlink}</span>
                </div>

            </div>
        )
    }

    const [contentcard, setContentcard] = useState(title)

    return (
        <div>
            {contentcard}
        </div>
    )
}

export default ItemRender
