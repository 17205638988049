import React from 'react'
import './style.css'
import Options from './Options'
import Footer from '../footer/Footer'
import { Carousel } from 'react-responsive-carousel'
import icontlf from '../../images/icon/telefono-blanco.png'
import iconmail from '../../images/icon/mail-blanco.png'
import iconmap from '../../images/icon/ubicacion-blanco.png'
import logo from '../../logofisio.PNG'
import logotext from '../../logo-text.png'
import { navigateFunction } from '../../functions'
import logoytb from '../../images/icon/ytb.png'
import imgHor from '../../images/horario.png'


const MobileApp = () => {

    const data = [
        { image: 'casos/1.png' },
        { image: 'casos/2.png' },
        { image: 'casos/3.png' },
        { image: 'casos/4.png' },
        { image: 'casos/5.png' },
        { image: 'casos/6.png' },
        { image: 'casos/7.png' }
    ]
    return (
        <div style={{ background: 'white' }}>
            <div className="header sticky-top">
                <div className="text-center mb-2">
                    <img src={logo} width="80" height="80" className="d-inline-block" alt="logofoto" />
                    <img src={logotext} width="80" height="80" className="d-inline-block" alt="logotext" />
                </div>
            </div>
            
            <div style={{ width: '100%', height: '100%' }}>
                <Carousel
                    showStatus={false}
                    showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    interval="4000"
                    stopOnHover={false}
                    infiniteLoop
                    autoPlay
                >
                    {data.map((item) => {
                        return (
                            <ul className="list-unstyled carousel">
                                <li key={item.id}>
                                    <img alt={item.id} src={require(`../../images/${item.image}`)} />
                                </li>
                            </ul>
                        )
                    })}
                </Carousel>
            </div>

            <div className="row text-center py-2 px-2 trato">
                <span className="my-font">Trato <span className="bold-text">100%</span> personalizado</span>
            </div>
            <div className="row text-center py-2 px-2 trato">
                <a href="tel:+34690051174">
                    <img src={icontlf} width="35" height="35" alt="logotlf" />
                </a>
                <div style = {{marginLeft:35 , marginRight:35}} onClick = {() => navigateFunction(41.7363098, -1.1015243)}>
                    <img src={iconmap} width="35" height="35" alt="logomap" />
                </div>
                <a href="mailto:info@fisioterapiapinseque.com">
                    <img src={iconmail} width="35" height="35" alt="logoemail" />
                </a>
            </div>
            <div className="row text-center preocupados">
                <span className="my-font " style = {{paddingLeft: '25px' , paddingRight: '25px'}}>Preocupados por tu salud y bienestar</span>
            </div>

            {/* <div className="" >
                    <p style={{
                        fontFamily: 'caviar',
                        fontSize: '12px',
                        textAlign: 'center',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        color: 'black'
                    }}>
                        Nuestros tratamientos se basan en la
                                <span> educación terapéutica</span> para que el paciente pueda entender qué pasa, cuando y porqué
                            </p>
                    <p style={{
                        fontFamily: 'caviar',
                        fontSize: '22px',
                        textAlign: 'center',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        color: 'black'
                    }}>  El<span> acondicionamiento</span> a través de ejercicio terapéutico en personas con patología,
                                así como grupos reducidos de ejercicio de fortalecimiento y movilización
                            </p>
                    <p style={{
                        fontFamily: 'caviar',
                        fontSize: '22px',
                        textAlign: 'center',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        color: 'black'
                    }}>
                        Y la<span> terapia manual</span> con el objetivo de modificación de los síntomas con los que
                                entráis a la clínica, y con todo ello producir una mejora en la calidad de vida
                            </p>
                </div> */}
            <div className="justify-content-center text-center">
                <Options className="d-block justify-content-center" />
            </div>
            <div className="justify-content-center text-center">
            <span 
            style = {{
                fontFamily: 'bebas',
                color: '#2BADAD',
                fontSize:25
            }}
            >Horario</span>
            </div>
            
            <div 
            className = 'horariosImg' 
            style = {{ 
                height: 376,
                backgroundImage: `url(${imgHor})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%'
                }}/>
            <div id='rrss' className="justify-content-center text-center" style = {{ padding: '10px' , background: 'whitesmoke'}}>
                <div className = 'row'><span style = {{fontFamily: 'bebas' , textTransform: 'uppercase' , color: '#2badad' , fontSize: 20, marginBottom: 7}}>redes sociales</span></div>
                <a href = 'https://www.youtube.com/channel/UC5NuzQwLQjJch544X0zQcmQ/videos' target="_blank" rel="noopener noreferrer">
                    <img src = {logoytb} width = '35px' height = '25px' alt = 'logoytbmobile'/>
                </a>
            </div>
        </div>
    )
}


export default MobileApp