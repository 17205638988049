export const FETCH_PHRASE_REQUEST = 'FETCH_PHRASE_REQUEST';
export const FETCH_PHRASE_SUCCESS = 'FETCH_PHRASE_SUCCESS';

export const ADD_PHRASE_REQUEST = 'ADD_PHRASE_REQUEST';

export const DELETE_PHRASE_REQUEST = 'DELETE_PHRASE_REQUEST';
export const DELETE_PHRASE_SUCCESS = 'DELETE_PHRASE_SUCCESS';


// export const UPDATE_PHRASE_REQUEST = 'UPDATE_PHRASE_REQUEST';
// export const UPDATE_PHRASE_SUCCESS = 'UPDATE_PHRASE_SUCCESS';
// export const UPDATE_PHRASE_ERROR = 'UPDATE_PHRASE_ERROR';
