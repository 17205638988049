import React, {useState, useEffect } from 'react';
import {Route, Router, useLocation} from 'react-router-dom'
import history from './history'
import Navbar from './components/navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css'
import Contenido from './components/results'
import Tarifas from './components/tarifas'
import TarifasFisio from './components/tarifas/trffisio.js'
import TarifasEj from './components/tarifas/trfej.js'
import Clinica from './components/clinica'
import Details from './components/details'
import Inicio from './components/inicio'
import Equipo from './components/equipo';
import Footer from './components/footer/Footer'
import Contacto from './components/contacto'
import Admin from './components/admin'
import Policy from './components/policy'
import Formacion from './components/formacion'
import './App.css'
import './components/styles/mystyles.css'
import MobileApp from './components/mobileweb'

const App = (props) => {

  useEffect(
    () =>{
        window.addEventListener('resize',handleResize)
        window.addEventListener('popstate', handleHashChange)
        return () => {
        window.removeEventListener('resize',handleResize)
        window.removeEventListener('popstate', handleHashChange)
        }
        }
    )

    const firstrender = window.innerWidth > 992 ? false : true

    const [tablet ,setTablet] = useState(firstrender)

    const handleResize = () =>{
        window.innerWidth > 992? 
        setTablet(false) 
        : 
        setTablet(true);
    }

    const handleHashChange = () => {
      const location = window.location
      console.log('path ', location)
    }


    return (
      <>
        {props.isMobile?
        <MobileApp/>
        :
        <>
          <div>
            <Router history = {history}>
              <div className = {tablet? null : "sticky-top"}>
                <Navbar/>
              </div>
              
              <Route exact path="/" component={Inicio} />  
              <div className ="panel-color" >
                {/* <Route component = {Error404}/> */}
                <Route exact path="/adminfisioweb" component= {Admin} />
                <Route exact path="/clinica" component={Clinica} />
                <Route exact path="/tarifas" component={Tarifas} />
                <Route exact path="/contacto" component={Contacto} />
                <Route exact path="/equipo" component={Equipo} />
                <Route exact path="/tratamientos" component={Contenido}/>
                <Route exact path="/formacion" component={Formacion}/>
                <Route exact path="/policy" component={Policy}/>
                <Route exact path="/details" component={Contenido}/>
                <Route exact path="/details/:itemId" component={Details} />
              </div>

              <Footer/>
              
            </Router>
          </div>
          
            
          
        </>
      }
      </>
    );

}

export default App;
