import React, { Component } from 'react';
import { array } from 'prop-types';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-confirm-alert/src/react-confirm-alert.css';
import policyDownload from '../../data/avle+popri_fispin20.pdf'

class FormContacto extends Component {

  static propTypes = {
    phrases: array
  };

  state = {
    name: '',
    email: '',
    msg: '',
    tel: '',
    policy: '',
    editKey: false
  };

  handleAceptPolicy = e => {
    this.setState({
      policy: e
    });
  }

  handleOnChange = e => {
    const { target: { name, value } } = e;

    this.setState({
      [name]: value
    });
  }

  aceptPolicy = () => {
    confirmAlert({
      title: 'Consentimiento legal',
      childrenElement: () =>
        <p>Entiendo y acepto la
          <a href='/policy'> politica de privacidad</a> y el
          <a href='#'> aviso legal</a>
        </p>
      ,
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => { this.handleAceptPolicy(true) ; this.requestMail() }
        },
        {
          label: 'Rechazar',
          onClick: () => this.handleAceptPolicy(false)
        }
      ]
    })
  }

  requestMail = () => {
    console.log('Entro a requestmail')
    const { name, email, tel, msg } = this.state

    const proxyurl = "https://cors-anywhere.herokuapp.com/"
    const url = `https://fisioterapiapinseque.com/api/mail`

    fetch(proxyurl + url, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'name': name,
        'email': email,
        'tel': tel,
        'msg': msg
      }
    }).then((res) => {
      console.log('res envio email', res)
      confirmAlert({
        title: 'Hecho',
        message: `Contactaremos contigo lo antes posible\n¡Muchas Gracias!`,
        buttons: [
          {
            label: 'De acuerdo',
            onClick: () => null
          }
        ]
      })
    })
      .catch((error) => {
        console.log(error)
        confirmAlert({
          title: 'Error',
          message: `Lamentablemente no ha funcionado :( \n Puedes enviar un correo a info@fisioterapiapinseque.com \n o llamar al 690 05 11 74`,
          buttons: [
            {
              label: 'De acuerdo',
              onClick: () => null
            }
          ]
        })
      })

    this.setState({
      name: '',
      email: '',
      msg: '',
      tel: ''
    });
  }

  handleSubmit = async e => {
    e.preventDefault()
    let date = moment().format('DD/MM/YY HH:mm')

    if (this.state.name && this.state.email && this.state.msg) {
      if (this.state.policy) {
        this.requestMail()
      }
      else{
        this.aceptPolicy()
      }
    }
    else {
      confirmAlert({
        title: 'Error',
        message: `Nombre, Email y Mensaje son obligatorios`,
        buttons: [
          {
            label: 'De acuerdo',
            onClick: () => null
          }
        ]
      })
    }
  }

  render() {
    const { isMobile } = this.props;
    const screenWidth = window.screen.width
    const width = screenWidth - screenWidth / 2.8
    return (
      <>
        <form onSubmit={this.handleSubmit} style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="form-group">
            <input
              name="name"
              type="text"
              className="form-control"
              style={{ width: isMobile ? width : '360px' }}
              id="formGroupExampleInput"
              placeholder="Nombre"
              onChange={this.handleOnChange}
              value={this.state.name} />
          </div>
          <div className="form-group">
            <input
              name="email"
              type="email"
              className="form-control"
              style={{ width: isMobile ? width : '360px' }}
              id="formGroupExampleInput2"
              placeholder="Email"
              onChange={this.handleOnChange}
              value={this.state.email} />
          </div>
          <div className="form-group">
            <input
              name="tel"
              type="text"
              className="form-control"
              style={{ width: isMobile ? width : '180px' }}
              id="formGroupExampleInput2"
              placeholder="Teléfono"
              onChange={this.handleOnChange}
              value={this.state.tel} />
          </div>
          <div className="form-group">
            <textarea
              name="msg"
              className="form-control"
              style={{ width: isMobile ? width : '360px' }}
              id="formGroupExampleInput2"
              placeholder="Mensaje"
              onChange={this.handleOnChange}
              value={this.state.msg} />
          </div>
          <div className='form-group'>
            <input
              type="submit"
              className="form-control"
              value="Enviar"
              style={{
                marginBottom: "7px",
                borderRadius: "50px",
                border: "1px solid white",
                background: "#9FD6D6",
                color: "white"
              }}
            />
          </div>
          <div className = 'row d-flex align-items-start' style = {{height: 30 }}>
            <Checkbox
              onChange = {() => this.handleAceptPolicy(!this.state.policy)}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <div>
              <p style = {{
                fontSize:13,
                marginTop:7
              }}>
                Entiendo y acepto la
              <a href='/policy'> politica de privacidad</a> y el
              <a href={policyDownload} download> aviso legal</a>
              </p>
            </div>
          </div>
        </form>

      </>

    );
  }
}

export default FormContacto;
