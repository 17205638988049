import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import CardMedia from '@material-ui/core/CardMedia';
import './style.css';

function Page(props) {

    const {
        goTo,
        currentItem,
    } = props;

    const stylespan = {
        color: '#DB79BD',
    }

    const isDiferent = () => {
        return currentItem.title !== 'Fisioterapia y mujer' && currentItem.title !== 'McKenzie'
    }

    console.log(isDiferent())

    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page">
                <Paper
                    elevation={1}
                    className="paper-container"
                >
                    {props.currentItem ?
                        <Fragment>
                            <div className="d-flex flex-row">
                                <Button
                                    style={{ color: "rgb(43,173,173)" }}
                                    onClick={() => goTo('/tratamientos')}
                                >
                                    VOLVER
                            </Button>
                            </div>
                            <hr />
                            {/* <CardMedia */}
                            {/* className="card-media"
                            //image={require(`../../images/${currentItem.image}`)}
                            style = {{borderRadius:"15px"}}
                            /> */}
                            <Typography gutterBottom variant="h5" component="h2"
                                style={{
                                    fontFamily: 'moviebold',
                                    fontSize: 45,
                                    textTransform: 'uppercase',
                                    letterSpacing: '3px',
                                    color: '#696b6b',
                                    fontWeight: 'bold'
                                }}
                            >
                                {currentItem.title}
                            </Typography>
                            <hr />
                            {isDiferent() ?
                                <Typography gutterBottom component="p" variant="p"
                                    className="content-typo"
                                    style={{
                                        fontFamily: 'caviar',
                                        fontSize: 26,
                                        justifyContent: "center",
                                        padding: '2rem 10rem'
                                    }}>
                                    {currentItem.content}
                                </Typography>
                                :
                                <div
                                    className="content-typo"
                                    style={{
                                        fontFamily: 'caviar',
                                        justifyContent: "center",
                                        padding: '2rem 10rem'
                                    }}>
                                    {currentItem.title === 'Fisioterapia y mujer' ?
                                        <div>
                                            <p style={{ fontSize: 26 }}>Las mujeres afrontamos diferentes cambios físicos, metabólicos y emocionales durante las diferentes etapas de la vida (como mujer joven, como madre, durante la menopausia, etc). La fisioterapia en el tratamiento y cuidado de la mujer tiene como objetivo resolver patologías que afectan a gran parte de la población femenina. Nosotros nos centraremos en cuatro áreas:</p>
                                            <p style={{ fontSize: 26 }}><span style={stylespan}>Preparación al parto:</span> el momento del parto es uno de los episodios más especiales para la mujer, pero también más traumático para su sistema músculo-esquelético. Desde la fisioterapia, os ayudaremos a preparar vuestro cuerpo para dicho momento a través de un programa de ejercicios específicos e individualizados para fortalecer la faja lumbo-pélvica, ganar flexibilidad y mantener un estado físico saludable de cara al momento del parto.</p>
                                            <p style={{ fontSize: 26 }}><span style={stylespan}>Recuperación Postparto:</span> Tras el nacimiento del bebé y del proceso del parto, toda la musculatura del suelo pélvico, abdomen y demás estructuras se ven sometidas a un sobreesfuerzo que pueden desencadenar determinadas secuelas, como dolor pélvico, cicatrices dolorosas, etc. A través de un trabajo específico de suelo pélvico e hipopresivos, recuperaremos el estado inicial previo al parto.</p>
                                            <p style={{ fontSize: 26 }}><span style={stylespan}>Uroginecología:</span>Problemas como incontinencias urinarias, diástisis abdominal entre otras patologías, tendrán tratamiento en nuestras sesiones de fisioterapia.
                                        Mujeres deportistas que su actividad física es con impacto como correr, saltar, bailar, halterofilia… buscaremos prevenir y cuidar vuestra musculatura para evitar futuros problemas.</p>
                                            <p style={{ fontSize: 26 }}><span style={stylespan}>Onco-fem:</span> algunas mujeres pasan por un proceso de cirugía mamaria y tratamientos posteriores con secuelas tanto físicas en general como a nivel de brazo. En este programa tratamos de forma individualizada todos aquellos problemas que sean derivados de este proceso, de una forma optimista, proactiva por parte de la paciente y con el objetivo de mejorar físicamente. Dentro de este apartado realizamos rehabilitación, tratamiento de cicatrices, recuperación del movimiento, preparación a la cirugía, y drenaje linfático en caso de linfedema.</p>
                                        </div>
                                        :
                                        <div>
                                            <p style={{ fontSize: 26 }}>El Método McKenzie de Diagnóstico y Terapia Mecánica (MDT), desarrollado por Robin McKenzie, fisioterapeuta neozelandés, es uno de los métodos de referencia mundial en lo que a columna vertebral se refiere y que mayor evidencia científica ha demostrado a nivel de la columna lumbar.</p>
                                            <p style={{ fontSize: 26 }}>Está basado en una historia clínica detallada y una exploración con una serie de movimientos repetidos, con los que según la respuesta obtenida, nos indicarán qué fuerzas mecánicas (movimientos o posturas) nos ayudarán a reducir el problema.</p>
                                            <p style={{ fontSize: 26 }}>A través de un plan de ejercicios muy sencillos y fáciles de hacer, elaborado a partir de la primera visita por un terapeuta cualificado, este método involucra al paciente de forma activa en su tratamiento, ya que está ampliamente demostrado que los tratamientos que se apoyan en un trabajo domiciliario son mucho más efectivos.</p>
                                        </div>
                                    }

                                </div>
                            }

                        </Fragment>
                        :
                        <CircularProgress className="item-loader" />
                    }
                </Paper>
            </div>
        </Fragment>
    );
}

export default Page;
