import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Equipo from './Equipo'
import Tratamientos from './Tratamientos';
import Tarifas from './Tarifas';
import Formacion from './Formacion';
import Contacto from './Contacto'; 
import Clinica from './Clinica'; 

class Options extends Component {
    render() {
        return (
            <Fragment>
                <CssBaseline />
                <div className="container d-flex flex-column">
                    <Equipo/>
                    <Clinica/>
                    <Tratamientos/>
                    <Formacion/>
                    <Tarifas/>
                    <Contacto/>
                </div>
            </Fragment>
        );
    }
}

export default Options