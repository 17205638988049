import React from 'react'
import Page from './page'

class Equipo extends React.Component {
    render(){
        return (  
            <Page/> 
                )
    }
 }
 export default Equipo