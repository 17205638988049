import React from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import logo from '../../logofisio.PNG'
import logotext from '../../logo-text.png'
import './style.css'
import logoytb from '../../images/icon/ytb.png';
import logoscz from '../../images/logo_szience.PNG'
import arrowDown from '../../images/icon/arrow-down.png'
import arrowUp from '../../images/icon/up-arrow.png'
import { tamVentana } from '../../functions'


class Navbar extends React.Component {

    constructor() {
        super()
        const width = tamVentana()[0]

        this.state = {
            tablet: width < 1251 ? true : false,
            show: true
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize = () => {
        this.setState({
            tablet: window.innerWidth > 1251 ? false : true
        })
    }

    setShow = () => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const styleActiveLink = {
            marginTop: '-0.5rem',
            paddingTop: '-0.5rem',
            color: '#616363',
            textDecoration: 'underline'
        }

        return (
            <div className='row' style={{ background: 'white', margin: 0 }}>
                {this.state.show ?
                    <>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid d-flex align-items-center" >
                                {this.state.tablet ?
                                    <img height='50px' width='75px' src={arrowUp} onClick={() => this.setShow()}></img>
                                    :
                                    null
                                }
                                <ul className="nav navbar-nav list-unstyled">
                                    {/* Inicio */}
                                    <li className="d-flex align-items-center logo-link">
                                        <div className="d-inline-block">
                                            <NavLink to="/" className="" id="eventlogo">
                                                <img src={logo} width="80" height="80" className="d-inline-block" alt="" />
                                                <img src={logotext} width="80" height="80" className="d-inline-block" alt="" />
                                            </NavLink>
                                        </div>
                                    </li>
                                    {/* Redireciones internas */}
                                    <li className=" col-sm col-lg p-4">
                                        <ul
                                            className={this.state.tablet ?
                                                "nav navbar-nav M- tablet-size"
                                                :
                                                "nav navbar-nav list-unstyled M-"}>
                                            <li><NavLink
                                                activeStyle={styleActiveLink}
                                                to="/clinica"
                                                className="nav-link d-inline-block">
                                                <span className="text-link">clínica</span>
                                            </NavLink></li>
                                            <li><NavLink
                                                activeStyle={styleActiveLink}
                                                to="/equipo"
                                                className="nav-link d-inline-block">
                                                <span className="text-link">quiénes somos</span>
                                            </NavLink></li>
                                            <li><NavLink
                                                activeStyle={styleActiveLink}
                                                to="/tratamientos"
                                                className="nav-link d-inline-block">
                                                <span className="text-link">tratamientos</span>
                                            </NavLink></li>
                                            <li><NavLink
                                                activeStyle={styleActiveLink}
                                                to="/formacion"
                                                className="nav-link d-inline-block">
                                                <span className="text-link">formación</span>
                                            </NavLink></li>
                                            <li><NavLink
                                                activeStyle={styleActiveLink}
                                                to="/tarifas"
                                                className="nav-link d-inline-block">
                                                <span className="text-link">tarifas</span>
                                            </NavLink></li>
                                            <li><NavLink
                                                activeStyle={styleActiveLink}
                                                to="/contacto"
                                                className="nav-link d-inline-block">
                                                <span className="text-link">contacto</span>
                                            </NavLink></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <div className='col container-fluid d-flex align-items-center'>
                            <div className='row' style={{ padding: 0 }}>
                                <a href='https://www.youtube.com/channel/UC5NuzQwLQjJch544X0zQcmQ/videos' target="_blank" rel="noopener noreferrer">
                                    <img src={logoytb} width="32.5" height="25" alt='logoytb' />
                                </a>
                            </div>

                        </div>
                    </>
                    :
                    <img height='50px' width='75px' src={arrowDown} onClick={() => this.setShow()}></img>
                }


            </div>
        )
    }
};

Navbar = withRouter(Navbar);
export default Navbar;
