// Dependencias
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Componentes
import Admin from './Admin';

// Acciones
import {
  fetchPhrases,
  deletePhrase
} from '../../redux/actions/phrasesActions';

const mapStateToProps = ({ phrases }) => ({
  phrases: phrases.phrases
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    fetchPhrases,
    deletePhrase
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);