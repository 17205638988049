import React from 'react';
import './style.css'
import CssBaseline from '@material-ui/core/CssBaseline';
import data from '../../data/equipo'

function Page() {
    return (
        <React.Fragment>
            <CssBaseline />
            <div className="equipo-page">
                <section style={{  background: 'transparent', position: 'relative', margin: 0 }}>
                    <div >
                        <div>
                            <h2
                                style={{
                                    color: '#2BADAD',
                                    fontFamily: 'moviebold',
                                    marginTop: 3,
                                    fontSize: '75px',
                                }}
                                htmlFor='linkteam'>
                                conócenos
                            </h2>
                        </div>
                        <h4
                        style = {{
                            fontFamily: 'caviar',
                            fontSize: '30px'
                        }}
                        >Un equipo de profesionales a tu servicio</h4>
                    </div>

                    {/* <div className='containerForma'>
                        <div className='fondoForma3' />
                    </div> */}
                </section>

                <div
                    style={{ display: 'flex', justifyContent: 'center', background: '#F5FBFB', borderRadius: '25px' }}>
                    <ul className="list-unstyled justify-content-center">
                        {
                            data.map(item =>
                                <li key={item.id} className="card" style={{ width: '38rem' }}>
                                    <img className="card-img-top" src={require(`../../images/${item.imagen}`)} alt="profile" />
                                    <div className="card-body">
                                        <h2 style = {{ textTransform: 'uppercase'}} className="card-title">{item.name}</h2>
                                        <h4>{item.estudio1}</h4>
                                        <h4>{item.estudio2}</h4>
                                        <p style={{ marginTop: "2rem" }}>{item.descripcionp1}</p>
                                        <p>{item.descripcionp2}</p>
                                    </div>
                                </li>)}
                    </ul>
                </div>

            </div>
        </React.Fragment>
    )
}

export default Page