

export default[
    {
        id: 1,
        name: "Clínica",
        path: '/clinica',
        icon: 'HouseIcon',
        coloricon: '#545454',
        textlink: 'Mira nuestras instalaciones'
    },
    {
        id: 2,
        name: "Nosotros",
        path: '/equipo',
        icon: 'PeopleIcon',
        coloricon: '#748E8D',
        textlink: 'Conoce al equipo'
    },
    {
        id: 3,
        name: "Tratamiento",
        path: '/tratamientos',
        icon: 'FitnessCenterIcon',
        coloricon: '#545454',
        textlink: 'Ver los tratameintos'
    },
    {
        id: 4,
        name: "Formación",
        path: '/contacto',
        icon: 'LocalLibraryIcon',
        coloricon: '#748E8D',
        textlink: '¿Dónde nos formamos?'
    },
    {
        id: 5,
        name: "Tarifas",
        path: '/tarifas',
        icon: 'EuroIcon',
        coloricon: '#545454',
        textlink: 'Consulta los precios'
    },
    {
        id: 6,
        name: "Contacto",
        path: '/contacto',
        icon: 'PhoneIcon',
        coloricon: '#748E8D',
        textlink: 'Haznos llegar tus preguntas'
    }
];