import React from 'react';
import Gallery from 'react-photo-gallery'
import './style.css';
import img from '../../images/clinica/img_entrada.jpg'


const Clinica = () => {
  let id = 0
  const photos = [
    {
      key: id++,
      src: img,
      width: 4,
      height: 3,
    },
    {
      key: id+1,
      src: img,
      width: 4,
      height: 3,
    }
  ];
  return (
    <>
      <div style={{ padding: '20px', }}>
        <p style={{
          fontFamily: 'caviar',
          fontSize: '25px',
          textAlign: 'center',
          marginLeft: '2rem',
          color: 'black'
        }}><span style = {{color:'#2BADAD' , fontWeight:'bold' , textAlign:'justify'}}>Fisioterapia Pedrola</span> nace con la idea de poder ofrecer el mejor servicio de fisioterapia a sus pacientes. Para ello contamos con unas instalaciones
          que favorecen un entorno cálido, agradable y confortable,
            ideal para el buen desarrollo de nuestros servicios y actividades.</p>
      </div>
      <Gallery photos={photos} direction="column" />
    </>
  )
}

export default Clinica