import React, { Component } from 'react';
import { array } from 'prop-types';
import './style.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CssBaseline from '@material-ui/core/CssBaseline';

class Admin extends Component {
    static propTypes = {
        phrases: array
    };

    componentWillMount() {
        this.props.fetchPhrases();
    }

    handleDeleteElement = (key, name) => {
        confirmAlert({
            title: 'Confirmar',
            message: `¿Eliminar definitivamente el mensaje de ${name}?`,
            buttons : [
                {
                    label: 'Sí',
                    onClick: () => this.props.deletePhrase(key)
                },
                {
                    label: 'No',
                    onClick: () => null
                },
            ]
        })
    }

    render(){
        
        const { phrases } = this.props;
        const orderlist = phrases.reverse();

        return(
            <>
                <CssBaseline />

                <div className = "d-block infocli justify-content-center">
                    {orderlist && orderlist.map(({ key, name, msg, email, tel, date}) => (
                    <Card key={key}  
                    className="card d-inline-block" 
                    style = {{height:400, margin:2}}>
                    <CardHeader title = {name}/>
                        <CardContent>
                            <p style = {{color: "black"}}>
                                {date}
                            </p>
                        
                            <div className = "msg-text">
                                <textarea
                                style = {{width:"100%" ,height:"210px", fontSize: "13px", color: "black"}}>
                                    {msg}
                                </textarea>
                            </div>

                            <a href={`mailto:${email}`}>
                                <p> {email} </p>
                            </a>

                            <a href={`tel:${tel}`}>
                               <p>{tel}</p> 
                            </a>

                            <a href="#" style = {{color:"red", float:"left"}} onClick={() => this.handleDeleteElement(key , name)}>Eliminar</a>

                        </CardContent>
                    </Card>
                    ))}
                </div>
            </>
        )

    }

}

export default Admin