import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import data from '../../data/images'
import datainicio from '../../data/inicioinfo'
import ItemRender from './ItemRender'
import { NavLink } from 'react-router-dom'
import './style.css';

function Page() {
    const data1 = datainicio.slice(0, datainicio.length / 2);
    const data2 = datainicio.slice(datainicio.length / 2, datainicio.length);

    return (
        <div>
            <CssBaseline />
            <Carousel
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                interval="4000"
                stopOnHover={false}
                infiniteLoop
                autoPlay
            >
                {data.map((item, index) => {
                    return (
                        <ul className="list-unstyled">
                            <li key={item.id}>
                                <img alt={index} style={{ height: 280 }} src={require(`../../images/${item.image}`)}></img>
                            </li>
                        </ul>
                    )
                })}
            </Carousel>

            <div className="row">
                <div className="col text-center py-4 trato">
                    <span className="my-font">Trato <span className="bold-text">100%</span> personalizado</span>
                </div>
                <div className="col text-center py-4 preocupados"><span className="my-font">Preocupados por tu salud y bienestar</span></div>
            </div>

            <div className="row">
                <div className="col-8 inicio-text" >
                    <p style={{
                        fontFamily: 'caviar',
                        fontSize: '26px',
                        textAlign: 'center',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        color: 'black'
                    }}>
                        Nuestros tratamientos se basan en la
                                <span> educación terapéutica</span> para que el paciente pueda entender qué pasa, cuando y porqué.
                            </p>
                    <p style={{
                        fontFamily: 'caviar',
                        fontSize: '26px',
                        textAlign: 'center',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        color: 'black'
                    }}>  En el<span> acondicionamiento</span> a través de ejercicio terapéutico en personas con patología,
                                así como grupos reducidos de ejercicio de fortalecimiento y movilización.
                            </p>
                    <p style={{
                        fontFamily: 'caviar',
                        fontSize: '26px',
                        textAlign: 'center',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        color: 'black'
                    }}>
                        Y la<span> terapia manual</span> con el objetivo de modificación de los síntomas con los que
                                entráis a la clínica, y con todo ello producir una mejora en la calidad de vida.
                            </p>
                </div>

                <div className="col py-4">
                    <div className="parallax prx1" />
                    <div className="parallax prx2" />
                </div>
            </div>

            <div className="row fexible-cards"
                style={{
                    padding: "2rem 8rem"
                }}>
                <ul className="list-unstyled vertical-list float-left">
                    {
                        data1.map(function (item) {
                            return (
                                <li key={item.id} style = {{marginBottom:30}}>
                                    <NavLink to={item.path}>
                                        <ItemRender item={item} />
                                    </NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
                <hr />
                <ul className="list-unstyled vertical-list float-right">
                    {
                        data2.map(function (item) {
                            return (
                                <li key={item.id} style = {{marginBottom:30}}>
                                    <NavLink to={item.path}>
                                        <ItemRender item={item} />
                                    </NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>

    );
}

export default Page