import React from 'react';
import data from '../../data/items'
import CardTratamiento from './CardTratamiento'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FitnessCenterIcon from '../../images/manos_naranja.png';

class Tratamientos extends React.Component {

    constructor(){
        super()
        this.state = {
            show : false, 
        };
    }

    showDetail = (e) =>{
        this.setState({
           show: !this.state.show
        })
    }

    render(){
        return (
        <div className="card-container" >
            <Card
            className="card"
            onClick={ this.state.show? null : this.showDetail }
            style = {{width: "100%" , background: 'whitesmoke'}}
            >
                {this.state.show?
                <>
                    <div className="option-title tratment-title">
                        <ExpandLessIcon
                        className = "expand-up-icon" 
                        fontSize="large" 
                        style={{ color: "lightsalmon" }} 
                        onClick={this.showDetail}/>
                        <CardContent>
                            <h2>Tratamientos</h2>
                        </CardContent>
                    </div>
                    <ul className="list-unstyled"
                        // style = {{ background: "radial-gradient(circle, rgba(46,200,201,1) 6%, rgba(240,234,223,1) 38%, rgba(78,205,169,1) 39%, rgba(55,73,68,1) 68%)"}}
                        >
                        {data.map(item =>
                        <div className="container d-flex flex-column">
                            <li key={item.id}>
                                <CardTratamiento item={item} />
                            </li>
                        </div>)}
                    </ul>
                    <ExpandLessIcon
                        fontSize="large" 
                        style={{ color: "lightsalmon" }} 
                        onClick={this.showDetail}/>
                </>
                :
                <CardActionArea>
                                    
                <div className = "d-flex justify-content-center">
                    <img style = {{marginRight: 16}} src= { FitnessCenterIcon } width= '35px' height= '35px'/> 
                    <h4>
                        Tratamientos
                    </h4>
                </div>
            
        </CardActionArea>
                }
            </Card>
            </div>
            );
        }    
}


export default Tratamientos