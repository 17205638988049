let id = 1
export default [
{
    id: id++,
    imagen: 'equipo/3carnet.jpg',
    name: 'JAVIER VIDAL MUNIESA',
    estudio1: 'Grado en Fisioterapia',
    estudio2: 'Grado en Ciencias de la Actividad Física y el Deporte',
    descripcionp1: 'Desde que desarrollo mi actividad profesional creo en la necesidad de atender íntegramente a la persona, teniendo la obligación de escucharla a través de una exhaustiva historia clínica con el objetivo de individualizar la intervención, así como de empoderarla a través de una educación y explicación de su problemática, ofreciéndole unas herramientas de manejo de aquella disfunción musculoesquelética que pueda presentar.',
    descripcionp2: 'En compromiso con las personas que atendemos, me he formado y actualizado en los últimos avances y vanguardias científicas que se están produciendo, para precisamente así poder garantizar la mayor excelencia profesional y calidad en nuestros tratamientos. Algunas de las formaciones que he realizado son método Mckenzie, punción seca (Travell y Simons), ejercicio terapéutico, entrenamiento de la fuerza, etc.',
},
{
    id: id++,
    imagen: 'equipo/2carnet.jpeg',
    name: 'RODRIGO RUIZ PANDO',
    estudio1: 'Grado en Fisioterapia',
    estudio2: '',
    descripcionp1: 'Mi principal interés se basa en el estudio de la neurofisiología del dolor, el razonamiento clínico y el aprendizaje motor. Tengo experiencia en el tratamiento de pacientes con alteraciones en el sistema nervioso central y periférico, contando con formación en Concepto Bobath, ejercicio terapéutico y pilates suelo. Interesado, también, por la fisioterapia cardiorrespiratoria.', 
    descripcionp2: 'Siguiendo la línea de trabajo de la clínica Fisioterapia Pinseque, combino el trabajo de terapia manual con la educación terapéutica y técnicas de autotratamiento, otorgando al paciente una serie de posibilidades que le permitan dar solución a sus procesos de dolor y proporcionar una mejora en la calidad de vida a largo plazo.'
},
{
    id: id++,
    imagen: 'equipo/1carnet.png',
    name: 'ESTHER GARCÍA HIJAR',
    estudio1: 'Grado en Fisioterapia',
    estudio2: '',
    descripcionp1: 'Soy Esther García, graduada en fisioterapia por la universidad San Jorge.Siempre he estado muy vinculada al deporte por lo que tengo especial interés en la fisioterapia deportiva, prevención de lesiones y return to play. Me gusta tratar a cada persona y su lesión de una manera individualizada, combinando la terapia manual, ejercicio terapéutico y dando pautas para poder entender y comprender la dimensión de la disfunción haciéndoles partícipes, también, del tratamiento.',
    descripcionp2:"Además, estoy muy interesada en la fisioterapia especializada en los más peques, formándome para ello con cursos como: 'Experto universitario en fisioterapia pediátrica' y ' evaluación fisioterápica en atención temprana'.También tengo formación en punción seca y electropunción, pilates y vendajes neuromusculares y funcionales."
}

]