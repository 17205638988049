import React, { Component, Fragment } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import image2 from '../../images/equipo/1carnet.png'
import image1 from '../../images/equipo/2carnet.jpeg'
import image3 from '../../images/equipo/3carnet.jpg'
import team from '../../images/equipo/equipofullmid.png'
import data from '../../data/equipo'
import PeopleIcon from '@material-ui/icons/People';    

class Equipo extends Component {

    constructor(props){
        super(props)
        this.state = {
            show : false,
            member1: false, 
            member2: false,
            member3: false,     
        };
    }

    showDetail = (e) =>{
        this.setState({
           show: !this.state.show
        })
    }

    showMember1 = (e) =>{
        this.setState({
           member1: !this.state.member1
        })
    }
    showMember2 = (e) =>{
        this.setState({
           member2: !this.state.member2
        })
    }

    showMember3 = (e) =>{
        this.setState({
           member3: !this.state.member3
        })
    }


    render() {
        const emp1 = data.find(item => item.id === 1);
        const emp2 = data.find(item => item.id === 2);
        const emp3 = data.find(item => item.id === 3);
        return (
            <Fragment>
            <CssBaseline />
                <div className="card-container" >
                        <Card
                            className="card"
                            onClick={ this.state.show? null : this.showDetail }
                            style = {{width: "100%" , background: 'whitesmoke'}}
                            >
                            {this.state.show?
                                <>
                                <div className="option-title team-title">
                                    <ExpandLessIcon
                                    className = "expand-up-icon" 
                                    fontSize="large" 
                                    style={{ color: "hotpink" }} 
                                    onClick={this.showDetail}/>
                                    <CardContent>
                                        <h2>Nosotros</h2>
                                    </CardContent>
                                </div>
                                <div className = 'header-teamcard'>
                                    {/* <div style = {{
                                        height: 200,
                                        width: '100%',
                                        backgroundImage: `url(${team})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}/> */}

                                    <div className= "text-center py-2 inicio-text">
                                        <p>
                                            Nuestros tratamientos se basan en la
                                            <span> educación terapéutica</span> para que el paciente pueda entender qué pasa, cuando y porqué
                                        </p> 
                                        <p>  El<span> acondicionamiento</span> a través de ejercicio terapéutico en personas con patología, 
                                            así como grupos reducidos de ejercicio de fortalecimiento y movilización
                                        </p> 
                                        <p>
                                            Y la<span> terapia manual</span> con el objetivo de modificación de los síntomas con los que 
                                            entráis a la clínica, y con todo ello producir una mejora en la calidad de vida
                                        </p>
                                    </div>
                                </div>
                                <div className = 'mt-3'>
                                    <div className = "infomember"> 
                                        <img alt = 'imgJavier' className= "imagemember" src = {image3}/>
                                        <CardContent>
                                            <div className = "row">
                                                <h3>{emp1.name}</h3>
                                                {this.state.member1?
                                                <ExpandLessIcon
                                                className = "expand-member-icon" 
                                                fontSize="large" 
                                                style={{ color: "blue" }} 
                                                onClick={this.showMember1}/>
                                                :
                                                <ExpandMoreIcon
                                                className = "expand-member-icon" 
                                                fontSize="large" 
                                                style={{ color: "blue" }} 
                                                onClick={this.showMember1}/>
                                                }
                                            </div>
                                        
                                        {this.state.member1?
                                        <>
                                            <h4>{emp1.estudio1}</h4>
                                            <h4>{emp1.estudio2}</h4>
                                            <p>{emp1.descripcionp1}</p>
                                            <p>{emp1.descripcionp2}</p>
                                        </>
                                        :null}
                                        </CardContent>
                                    </div>
                                    
                                    <div className = "infomember"> 
                                        <img alt = 'imgSandra' className= "imagemember" src = {image1}/>
                                        <CardContent>
                                            <div className = "row">
                                                <h3>{emp2.name}</h3>
                                                {this.state.member2?
                                                <ExpandLessIcon
                                                className = "expand-member-icon" 
                                                fontSize="large" 
                                                style={{ color: "blue" }} 
                                                onClick={this.showMember2}/>
                                                :
                                                <ExpandMoreIcon
                                                className = "expand-member-icon" 
                                                fontSize="large" 
                                                style={{ color: "blue" }} 
                                                onClick={this.showMember2}/>
                                                }
                                            </div>
                                        
                                        {this.state.member2?
                                        <>
                                            <h4>{emp2.estudio1}</h4>
                                            <h4>{emp2.estudio2}</h4>
                                            <p>{emp2.descripcionp1}</p>
                                            <p>{emp2.descripcionp2}</p>
                                        </>
                                        :null}
                                        </CardContent>
                                    </div>

                                    <div className = "infomember"> 
                                        <img alt = 'imgRodrigo' className= "imagemember" src = {image2}/>
                                        <CardContent>
                                            <div className = "row">
                                                <h3>{emp3.name}</h3>
                                                {this.state.member3?
                                                <ExpandLessIcon
                                                className = "expand-member-icon" 
                                                fontSize="large" 
                                                style={{ color: "blue" }} 
                                                onClick={this.showMember3}/>
                                                :
                                                <ExpandMoreIcon
                                                className = "expand-member-icon" 
                                                fontSize="large" 
                                                style={{ color: "blue" }} 
                                                onClick={this.showMember3}/>
                                                }
                                            </div>
                                        
                                        {this.state.member3?
                                        <>
                                            <h4>{emp3.estudio1}</h4>
                                            <h4>{emp3.estudio2}</h4>
                                            <p>{emp3.descripcionp1}</p>
                                            <p>{emp3.descripcionp2}</p>
                                        </>
                                        :null}
                                        </CardContent>
                                    </div>

                                </div> 
                                <ExpandLessIcon
                                className = "expand-icon" 
                                fontSize="large" 
                                style={{ color: "hotpink" }} 
                                onClick={this.showDetail}/>
                                </>
                                :
                                <CardActionArea>
                                    
                                        <div className = "d-flex justify-content-center">
                                            <PeopleIcon 
                                            className="align-self-center mr-3 teamicon" 
                                            fontSize="large"
                                            />
                                            <h4>
                                                Nosotros
                                            </h4>
                                        </div>
                                    
                                </CardActionArea>
                            }
                        </Card>
                    
                </div>
        </Fragment>

        );
    }
}

export default Equipo