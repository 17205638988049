import React from 'react';
import imgTarFis from '../../images/tarifas/fisio.jpeg'
import imgTarEj from '../../images/tarifas/ejdir.jpeg'
import imgDownload from '../../images/tarifas/Tarifas2022FisioPinseque.pdf'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EuroIcon from '@material-ui/icons/Euro';

class Tarifas extends React.Component {

    constructor() {
        super()
        this.state = {
            show: false,
        };
    }

    showDetail = (e) => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {

        return (
            <div className="card-container" style = {{alignItems: 'center', justifyContent: 'center' , display: 'flex'}} >
                <Card
                    className="card"
                    onClick={this.state.show ? null : this.showDetail}
                    style={{ width: "100%" , background: 'whitesmoke'}}
                >
                    {this.state.show ?
                        <>
                            <div className="option-title tarifa-title">
                                <ExpandLessIcon
                                    className="expand-up-icon"
                                    fontSize="large"
                                    style={{ color: "#BD82DE" }}
                                    onClick={this.showDetail} />
                                <CardContent>
                                    <h2>Tarifas</h2>
                                </CardContent>
                            </div>
                            <div style={{ alignContent: 'content' }}>
                                <img alt='imgTarFis' src={imgTarFis} width='100%' />
                                <hr />
                                <img alt='imgTarEj' src={imgTarEj} width='100%' />
                            </div>
                            <div style = {{alignItems: 'center', justifyContent: 'center', borderRadius: 150 , backgroundColor: '#BDE5E2' , marginTop: 15 }}>
                                <a style = {{ color: 'white' , fontWeight: 'bold' }} href={imgDownload} download >
                                    DESCARGAR TARIFAS
                                </a>
                            </div>
                            <ExpandLessIcon
                                fontSize="large"
                                style={{ color: "#BD82DE" }}
                                onClick={this.showDetail} />
                        </>
                        :
                        <CardActionArea>

                            <div className="d-flex justify-content-center">
                                <EuroIcon
                                    className="align-self-center mr-3 tarifaicon"
                                    fontSize="large"
                                />
                                <h4>
                                    Tarifas
                                </h4>
                            </div>

                        </CardActionArea>
                    }
                </Card>
            </div>
        );
    }
}


export default Tarifas