import React from 'react'

const Policy = ( aceptPolicy ) => {
    return (
        <div className='container p-4'>
            <h1>POLÍTICA DE PRIVACIDAD</h1>
            <p>De conformidad con lo dispuesto en la normativa vigente en lo relativo a la protección de datos el Reglamento Europeo 2016/679 (RGPD) y la ley orgánica 3/2018 de protección de datos personales y garantía de los derechos digitales (LOPD) a continuación le comunicamos el tratamiento que realizamos de sus datos:
             </p>
            <h3>¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?</h3>
            <p>
                Responsable: JAVIER VIDAL MUNIESA (a partir de ahora FISIOTERAPIA PINSEQUE) <br />
                  Domicilio social: C/ SAN PEDRO MARTIN N.º 15 LOCAL DERECHO PINSEQUE 50298 ZARAGOZA<br />
                  TLF: 690 05 11 74<br />
                  Email: info@fisioterapiapinseque.com<br />
                  Datos de contacto delegado de protección de datos: datusmas@datusmas.com
            </p>
            <h3> ¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS PERSONALES?</h3>
            <br />
            <h4>SUS DATOS PERSONALES SÓLO SE UTILIZARÁN PARA LAS SIGUIENTES FINALIDADES, LEGITIMACIONES Y TIEMPO DE CONSERVACION:</h4>
            <p>
                Como Usuario de la Web (formulario de contacto /mails remitidos a las direcciones de correo que aparecen en la página): Tus datos serán utilizados para dar respuestas a tus solicitudes de información, comentarios o sugerencias, a través del apartado de contacto o de las direcciones de correo que aparezcan en nuestra página y mantener la comunicación con el interesado. Legitimación: Consentimiento/solicitud de medidas precontractuales (solicitud de información concreta sobre nuestros servicios en fisioterapia) conforme a los Art. 6.1.a) y Art.6.1. b) del RGPD respectivamente. Conservación: Conservados hasta cumplir su finalidad o hasta que nos revoque el consentimiento prestado.<hr />
                 Como seguidor en Redes Sociales: Utilizamos redes sociales para informar sobre nuestras actividades e interactuar con nuestros seguidores. Al hacerte seguidor nuestro, consientes que trate los datos personales que estén disponibles en tu perfil, exclusivamente para dicha finalidad y sólo en el entorno de cada red social conforme a sus políticas de uso y privacidad.  Sus datos personales serán utilizados con la finalidad de gestionar el listado de personas a las que les gusta nuestra página. Y poder así recibir información directamente relacionada con los servicios que prestamos, eventos, actividades y promociones de nuestra organización, siempre a través de la red social elegida e interactuar con nosotros. Legitimación: Tratamiento realizado en base al consentimiento del interesado Art. 6.1.a) del RGPD. Conservación: Serán conservados hasta que nos revoque el consentimiento prestado o dejes de seguirnos o marques ya no me gusta. Comunicación de los  datos: la información facilitada por el usuario a través de las redes sociales del responsable, incluidos sus datos personales, puede ser publicada, siempre en función de los servicios que el usuario utilice, por lo que podrá quedar a disposición pública de otros terceros usuarios de las redes sociales. Desde el perfil de cada red social, el usuario puede configurar qué información quiere hacer pública en cada caso, ver los permisos que se han concedido, eliminarlos o desactivarlos, como cualquier aplicación de un tercero que ya no se desea utilizar. <hr />
                Como Paciente: Tus datos serán utilizados para gestionar el tratamiento o la consulta fisioterapéutica que nos solicita y de las tareas administrativas derivadas de la prestación asistencial. Legitimación: La ejecución de un contrato para la prestación de la asistencia Fisioterapéutica que nos solicita (artículo 6.1.b RGPD) y su tratamiento sea realizado por un profesional sujeto a la obligación de secreto profesional (artículo 9.3 RGPD) El cumplimiento de obligaciones legales aplicables a mi actividad como Fisioterapeuta, derivadas de la Ley 41/2002 de autonomía del paciente y derechos y obligaciones en materia de información y documentación clínica. El cumplimiento de obligaciones legales (artículo 6.1.c RGPD) aplicables a mi actividad profesional derivadas de la legislación mercantil y tributaria. Conservación: Un mínimo de Cinco años conservaré los datos personales incluidos en su historia clínica a contar desde la fecha de alta de cada proceso asistencial, de acuerdo con la Ley 41/2002 de autonomía del paciente y derechos y obligaciones en materia de información y documentación clínica. Cuatro Años a efectos fiscales: Los libros de contabilidad y otros libros registros obligatorios según la normativa tributaria que proceda (IRPF, IVA, IS, etc.). Artículos 66 a 70 Ley General Tributaria. Seis años a efectos mercantiles: Libros, correspondencia, documentación y justificantes (facturas, documentos bancarios, contratos etc.). Art. 30 Código Comercio. <hr />
                Como proveedor: Tus datos serán utilizados para gestionar la relación como proveedor de nuestra empresa, así como la gestión económica derivada. Legitimación: en base a la relación contractual Art.6.1. b) y cumplimiento de obligaciones legales en el ámbito económico y fiscal Art. 6.1.c) del RGPD. Conservación: Cuatro Años a efectos fiscales: Los libros de contabilidad y otros libros registros obligatorios según la normativa tributaria que proceda (IRPF, IVA, IS, etc.). Artículos 66 a 70 Ley General Tributaria. Seis años a efectos mercantiles: Libros, correspondencia, documentación y justificantes facturas emitidas y recibidas, contratos, documentos bancarios, etc.). Art. 30 Código Comercio.
            </p>
            <h3>¿HAY OBLIGACIÓN DE FACILITAR ESOS DATOS PERSONALES? </h3>
            <p> En el contacto solicitado, se hace necesario que nos facilite algunos datos de contacto para poder dirigirnos a usted para poder prestarle el servicio que nos solicita.  </p>
            <h3>¿CUÁLES SON LAS CONSECUENCIAS DE NO HACERLO?</h3>
            <p>Si no nos facilita los datos mínimos de contacto no será imposible e inviable atenderle.</p>
            <h3>¿CÓMO HE OBTENIDOS SUS DATOS PERSONALES?</h3>
            <p>Los datos obtenidos no los ha proporcionado usted directamente.</p>
            <h3>¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS PERSONALES?</h3>
            <p>No existe ninguna cesión de datos prevista, salvo obligación legal.</p>
            <h3>¿CUÁLES SON SUS DERECHOS CUANDO ME FACILITA SUS DATOS PERSONALES?</h3>
            <p>
                Cualquier interesado podrá solicitar el ejercicio de los siguientes derechos: <br />
                   Derecho de acceso: El interesado tendrá derecho a obtener confirmación de si se están tratando o no datos personales que le conciernen. <br />
                   Derecho de rectificación: El interesado tendrá derecho a obtener la rectificación de los datos personales inexactos que le conciernan o incompletos. <br />
                   Derecho de supresión: El interesado tendrá derecho a obtener la supresión de los datos personales que le conciernan cuando los datos personales ya no sean necesarios en relación con los fines para los que fueron recogidos o tratados de otro modo. <br />
                   Derecho de limitación: Podrá solicitar la limitación del tratamiento de sus datos personales, en cuyo caso únicamente los conservaría para el ejercicio o la defensa de reclamaciones.<br />
                   Derecho a la portabilidad de sus datos: Por lo que puede solicitarnos que sus datos personales automatizados sean cedidos o transferidos a cualquier otra empresa que nos indique en un formato estructurado, inteligible y automatizado.<br />
                   Derecho de retirar el consentimiento: Usted tendrá derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento antes de su retirada.<br />
                   Derecho de oposición: El interesado tendrá derecho a oponerse al tratamiento de sus datos.
                 </p>
            <h3>
                ¿CÓMO PODRÁ EJERCITAR ESTOS DERECHOS?
                   </h3>
            <p>
                Ponemos a su disposición si nos lo solicitan los formularios donde podrá ejercitar estos derechos. Podrá solicitarnos en el correo info@fisioterapiapinseque.com indicándonos el derecho que quiere ejercitar y le enviaremos el formulario correspondiente. El ejercicio de los derechos podrá llevarlo a cabo mediante comunicación dirigida al correo electrónico info@fisioterapiapinseque.com ¿QUIÉN PUEDE EJERCITAR LOS DERECHOS? Los derechos de los interesados son personalísimos, por lo tanto, serán ejercidos por el titular de los datos acreditando debidamente su identidad (para ello se le solicitará el DNI o equivalente). <br />
                   También podrá ejercerse a través de representación legal, en cuyo caso, además del DNI del interesado o equivalente, habrá de aportarse DNI y documento acreditativo auténtico de la representación del tercero.
                   </p>
            <h3>
                ¿CUÁL SERÁ NUESTRA OBLIGACIÓN CUANDO EJERCITA ALGUNO DE LOS DERECHOS?
                   </h3>
            <p>
                El responsable del tratamiento deberá contestar la solicitud que se le dirija en todo caso, con independencia de que figuren o no datos personales del afectado o interesado en sus tratamientos. <br />
                      En el caso de que la solicitud no reúna los requisitos especificados, el responsable del fichero deberá solicitar la subsanación de estos. <br />
                       El responsable de tratamiento responderá a las solicitudes en el plazo de un mes a partir de la recepción de la solicitud. Dicho plazo podrá prorrogarse otros dos meses en caso necesario, teniendo en cuenta la complejidad y el número de solicitudes.  El responsable informará al interesado de cualquiera de dichas prórrogas en el plazo de un mes a partir de la recepción de la solicitud, indicando los motivos de la dilación.
                       </p>
            <h3> DERECHO DE RECLAMACIÓN A LA AUTORIDAD DE CONTROL</h3>
            <p>Usted, podrá solicitar la tutela de derechos que no hayan sido debidamente atendidos a la Agencia Española de Protección de datos. Bien a través de la sede electrónica de su portal web (www.agpd.es), o bien mediante escrito dirigido a su dirección postal (C/Jorge Juan, 6, 28001-Madrid).</p>   
            <h3>CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR EL USUARIO</h3>
            <p>Los Usuarios, mediante la marcación de las casillas correspondientes y entrada de datos en los campos de los formularios de contactos, o bien enviado un correo electrónico solicitando información, aceptan expresamente y de forma libre e inequívoca, que sus datos son necesarios para atender su petición, por parte del prestador. El Usuario garantiza que los datos personales facilitados son veraces y se hace responsable de comunicar cualquier modificación de los mismos. </p> 
            <h3>¿QUÉ INFORMACIÓN RECOPILAMOS?</h3>
            <p>
            En general, puede usar el Sitio Web sin facilitar ninguna información de carácter personal. <br/> 
            Existen canales en nuestra página web donde se puede poner en contacto con nosotros. Cuando nos solicita información, el usuario garantiza la autenticidad, exactitud y veracidad de toda la información que nos facilite, comprometiéndose a mantener actualizados los datos de carácter personal que nos facilite de forma que los mismos respondan, en todo momento, a su situación real. El Usuario será el único responsable de las manifestaciones falsas o inexactas y de los perjuicios que las mismas pudieran causar. 
            </p>
            <h3>¿QUÉ MEDIDAS ADOPTAMOS PARA MANTENER SEGURA SU INFORMACIÓN?</h3>
            <p>Aplicamos medidas técnicas y físicas razonables para proteger la información que recogemos a través del Sitio Web.</p>  
            <h3>MODIFICACIONES DE LA PRESENTE INFORMACIÓN DE PROTECCION DE DATOS. </h3>
            <p>FISIOTERAPIA PINSEQUE se reserva el derecho a modificar su Política de Protección de datos de acuerdo con la legislación aplicable en cada momento. Cualquier modificación de dicha política será publicada en el sitio web.</p>  
        </div>
    )
}

export default Policy