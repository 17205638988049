import React, { Component } from 'react';
import Page from './page'


class Inicio extends Component {
    render() {
        return (
            <Page />
        );
    }
}

export default Inicio