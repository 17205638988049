import { createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import phrases from './reducers/phrasesReducer'
import results from './reducers/results';
import currentItem from './reducers/currentItem';
import randomItem from './reducers/randomitem'

const reducer = combineReducers({
    phrases,
    results,
    currentItem,
    randomItem,
});

export default function configureStore(initialState) {
  const middleware = [
    thunk
  ];

  return createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

}



