let id = 1;

export default [
    {
        id: id++,
        title: `Terapia Manual`,
        image: `terapias/manual.jpg`,
        content: `Los procedimientos de intervención de terapia manual, incluyen un amplio abanico de técnicas entre las que se incluyen la movilización articular, manipulación articular, estiramiento muscular, masaje funcional, movilización del sistema nervioso, todo ello con dos objetivos: modificar los síntomas del paciente (dolor) y la mecánica (el rango de movimiento).`,
    },
    {
        id: id++,
        title: `McKenzie`,
        image: `terapias/mckenzie.jpg`,
        content: `El Método McKenzie de Diagnóstico y Terapia Mecánica (MDT), desarrollado por Robin McKenzie, fisioterapeuta neozelandés, consiste en la aproximación terapéutica extensa al dolor de origen musculoesquelético no específico.

        Es un método de diagnóstico y terapia mecánica basado en una historia clínica detallada y una exploración con una serie de movimientos repetidos que, según la respuesta obtenida, nos indicarán qué fuerzas mecánicas (movimientos o posturas) nos ayudarán a reducir el problema.
        
        Este método enfatiza dos cosas:
        1.- El auto-tratamiento del paciente, a través de un plan de ejercicios muy sencillos y fáciles de hacer, elaborado a partir de la primera visita, por unterapeuta cualificado.
        2.- La independencia del terapeuta
        
        El objetivo es que el paciente reconozca las causas de sus dolencias y sea capaz de reducir y evitar las recurrencias de su problema.
        Especialmente indicado en problemas de espalda
        `,
    },
    {
        id: id++,
        title: `Ejercicio Terapéutico`,
        image: `terapias/ejterapeutico.jpg`,
        content: `Mediante el análisis detallado de los patrones de movimiento, elaboramos programas específicos de ejercicio dirigidos a restaurar la fuerza y la resistencia muscular, la amplitud de los movimientos y la coordinación adecuada de los mismos `,
    },
    {
        id: id++,
        title: `Punción Seca`,
        image: `terapias/puncion.jpg`,
        content: `Mediante la introducción de agujas finas filiformes en los músculos afectados conseguimos aliviar el dolor procedente del músculo así como contribuir a normalizar su grado de tensión y su correcto funcionamiento`,
    },
    {
        id: id++,
        title: `Fibrólisis Diacutánea`,
        image: `terapias/fibrolisis.jpg`,
        content: `Tratamiento de las algias del aparato locomotor, cuyo objetivo es liberar los planos de deslizamiento inter-tisulares de sus adherencias y/o corpúsculos irritativos, por movilizaciones tisulares analíticas y específicas. Se realiza mediante unos ganchos aplicados contra la piel, que permiten la invaginación de ésta hasta alcanzar la estructura etiopatológica a tratar`,
    },
    {
        id: id++,
        title: `Fisioterapia y mujer`,
        image: `terapias/mujer.jpg`,
        content: `o   Obstetricia: El momento del parto es uno de los episodios más especiales para la mujer, pero también más traumático su sistema músculo esquelético. Una buena preparación evita futuros problemas tanto en el momento del parto como en el estado físico posterior. Existe una gran variedad de alteraciones y trastornos como pueden ser: desgarros musculares, prolapsos, incontinencia de esfuerzo, diástasis abdominal etc. Con nuestro programa vamos a intentar minimizarlos y llegar en las mejores condiciones físicas posibles y tratarlos en caso de que aparezcan.
                     
        o   Preparación al parto:
        Mujer activa y suelo pélvico.Programa de ejercicios específicos e individualizados para fortalecer la faja lumbo-pélvica, ganar flexibilidad y mantener un estado físico saludable de cara al momento del parto.
        
        o   Postparto:
        
        Mujer activa e hipopresivos: programa de ejercicios genéricos e hipopresivos para fortalecer el suelo pélvico, y la faja abdominal. El objetivo es recuperar el estado inicial previa al parto, disminuyendo las secuelas y previniéndolas.

        o   Uroginecología: fisioterapia individualizada pre y post parto. Tratamientos individualizados de fisioterapia donde tratamos de forma específica patologías que hayan aparecido o prevenimos complicaciones facilitando la fase del expulsivo. Tratamientos para diástasis abdominal, incontinencia de esfuerzo, etc.
        
         
        
         
        
        o   Onco-fem: algunas mujeres pasan por un proceso de cirugía mamaria y tratamientos posteriores con secuelas tanto físicas en general como a nivel de brazo. En este programa tratamos de forma individualizada todos aquellos problemas que sean derivados de este proceso, de una forma optimista, proactiva por parte de la paciente y con el objetivo de mejorar físicamente. Dentro de este apartado realizamos rehabilitación, tratamiento de cicatrices, recuperación del movimiento, preparación a la cirugía, y drenaje linfático en caso de linfedema.`,
    },
    
];
