import React, { Component } from 'react'
import credentials from '../data/credentials'
import  GoogleMaps  from 'simple-react-google-maps'

class MyMap extends Component {
  render() {
    return (
      <GoogleMaps
        apiKey={credentials.key}
        style={{ height: "100%", width: "100%" }}
        zoom={16}
        center={{ lat: 41.7363098, lng: -1.1015243 }}
        markers={{ lat: 41.7363098, lng: -1.1015243 }}
      />
    )
  }
}

export default MyMap
